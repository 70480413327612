<template>
  <div class="payment">
    <div class="payment-header">
      <div class="payment-header-fruit">
        <van-image class="payment-header-fruit-img" fit="contain" :src="imgUrl" />
        <div>付款成功啦！</div>
      </div>
    </div>
    <div class="payment-content">
      <div class="payment-content-describe">
        <div>我们为您提供全方位的服务，猜您想：</div>
      </div>
      <div class="payment-content-pass">
        <!-- <div class="pcp-invoice">
          <van-button class="pcp-invoice-but">开电子发票</van-button>
        </div> -->
        <div class="pcp-water">
          <!-- <van-button class="pcp-water-but" plain>查看我的用水</van-button> -->
          <van-button class="pcp-water-but" plain @click="gotoHome">返回首页</van-button>
        </div>
      </div>
    </div>
    <div v-if="!isCertified" class="payment-foot">
      <div class="payment-foot-proposal">
        <div class="pfp-describe">
          您还可以：
        </div>
        <div class="pfp-link">
          <div @click="footRealName">认证</div>
          <!-- <div>充值缴费</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: require('../../assets/icon/payment_success.png'),
      isCertified: store.getters.homeInfo.isCertified
    };
  },
  methods: {
    gotoHome() {
      this.$router.push({ path: '/' });
    },
    footRealName() {
      // console.log(111);
      this.$router.push({ name: 'CertifiedPage', path: '/CertifiedPage' });
    }
  }
};
</script>

<style lang="less" scoped>
.payment {
  &-header {
    height: 146px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-fruit {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 146px;
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #303947;
      letter-spacing: 0;
      &-img {
        width: 56px;
        margin-right: 30px;
      }
    }
  }
  &-content {
    &-describe {
      display: flex;
      justify-content: center;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #9d9d9d;
      margin: 10px 0;
    }
    &-pass {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 88px;
      border-bottom: 1px solid #dadada;
      margin: 0 16px;
      .pcp-invoice {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
        .pcp-invoice-but {
          width: 90%;
          background: #0a79ec;
          border-radius: 8px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #ffffff;
        }
      }
      .pcp-water {
        width: 100%;
        display: flex;
        .pcp-water-but {
          width: 100%;
          border: 1px solid #999999;
          border-radius: 8px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #5e6268;
          letter-spacing: -0.42px;
        }
      }
    }
  }
  &-foot {
    margin: 0 16px;
    &-proposal {
      display: flex;
      align-items: center;
      height: 60px;
      .pfp-describe {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #9d9d9d;
        letter-spacing: -0.42px;
        margin-right: 10px;
      }
      .pfp-link {
        display: flex;
        align-items: center;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #0a79ec;
        letter-spacing: -0.42px;
        div {
          padding: 16px 8px;
        }
      }
    }
  }
}
</style>
